import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import Seo from '../components/seo/seo';
import Header from '../components/header/header';

import * as styles from '../scss/pages/index.module.scss';
import '../scss/styles.scss';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

const products = [
	{
		title: 'Krokiety',
		image: '/img/products/krokiety.png',
		details: [
			'krokiety z mięsem',
			'krokiety kapusta-grzyby',
			'krokiety kebab',
			'krokiety ser-pieczarka',
			'krokiety ser-pieczarka-kurczak',
			'krokiety szpinak',
			'krokiety meksykańskie'
		]
	},
	{
		title: 'Kapuśniaki',
		image: '/img/products/kapusniaki.png',
		details: []
	},
	{
		title: 'Paszteciki',
		image: '/img/products/paszteciki.png',
		details: []
	},
	{
		title: 'Placki ziemniaczane',
		image: '/img/products/placki-ziemniaczane.png',
		details: [
			'placki ziemniaczane tradycyjne',
			'placki ziemniaczane zagórskie z pokrzywą (Potrawa regionalna Chrzanolandia)'
		]
	},
	{
		title: 'Mięsa pieczone',
		image: '/img/products/miesa.png',
		details: [
			'devolay',
			'mielone',
			'kotlety sudeckie',
			'kotlety drobiowe',
			'kotlety schabowe',
			'kieszonki schabowe'
		]
	},
	{
		title: 'Roladki z udka',
		image: '/img/products/roladka.png',
		details: []
	},
	{
		title: 'Gołąbki',
		image: '/img/products/golabki.png',
		details: []
	},
	{
		title: 'Galaretki',
		image: '/img/products/galaretki.png',
		details: [
			'Galaretki drobiowe',
			'Galaretki wieprzowe'
		]
	},
	{
		title: 'Sałatki',
		image: '/img/products/salatki.png',
		details: [
			'Sałatka jarzynowa',
			'Sałatka porowa',
			'Sałatka królewska',
			'Sałatka z kurczakiem'
		]
	},
	{
		title: 'Śledzie',
		image: '/img/products/sledzie.png',
		details: [
			'śledź po japońsku',
			'śledz w śmietanie',
			'śledź w oleju',
			'śledź danusia'
		]
	},
	{
		title: 'Ryby',
		image: '/img/products/ryba.png',
		details: [
			'ryba w zalewie',
			'ryba po grecku'
		]
	},
	{
		title: 'Smalczyki',
		image: '/img/products/smalec.png',
		details: []
	},
	{
		title: 'Naleśniki z serem',
		image: '/img/products/nalesniki.png',
		details: []
	},
	{
		title: 'Pierogi 500g',
		image: '/img/products/pierogi.png',
		details: []
	},
	{
		title: 'Uszka 500g',
		image: '/img/products/uszka.png',
		details: []
	}
];

const IndexPage = () => {
	return(
		<>
			<Header />
			<Seo
				title="Zakład Garmażeryjny Brodway - Brodła"
				description="Zapraszamy do naszego Firmowego Sklepu Spożywczego, gdzie znajdziecie Państwo ogromny wybór wyrobów garmażeryjnych tj. krokiety, gołąbki, śledzie, sałatki, pasztety, pierogi , smalczyk  itd. - Zakład Garmażeryjny Brodway - Brodła"
				image=""
			/>

			<section className={styles.sectionMain}>
				<div className="container">
					<div className={styles.mainWrapper}>
						<div className={styles.copy}>
							<h1>Tradycyjne Polskie Potrawy</h1>
							<p>Nasze produkty wykonujemy ręcznie ze starannie dobranych składników, bez dodatku konserwantów, według tradycyjnej, polskiej receptury</p>
						</div>
						<div className={styles.mainSlider}>
							<Swiper
								spaceBetween={30}
								effect={'fade'}
								modules={[Autoplay, EffectFade]}
								autoplay={{
									delay: 4000,
									disableOnInteraction: false
								}}
								loop={true}
							>
								<SwiperSlide>
									<img src="/img/slider/krokiety.png" />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/img/slider/miesa.png" />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/img/slider/pierogi.png" />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/img/slider/golabki.png" />
								</SwiperSlide>
								<SwiperSlide>
									<img src="/img/slider/placki-ziemniaczane.png" />
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
				</div>
			</section>

			<section id="values" className={styles.sectionValues}>
				<div className="container">
					<div className={styles.valuesWrapper}>
						<div className={styles.box}>
							<div>
								<h2>Najwyższa jakość</h2>
								<p>Do produkcji naszych wyrobów nie używamy konserwantów, sztucznych barwników i spulchniaczy.</p>
							</div>
							<img src="/img/icons/badge.svg" alt="Najwyższa jakość" />
						</div>

						<div className={styles.box}>
							<div>
								<h2>Szacunek do tradycji</h2>
								<p>Poznawanie tradycji pomaga nam w odnalezieniu prawdziwych smaków z dawnych lat.</p>
							</div>
							<img src="/img/icons/ear.svg" alt="Szacunek do tradycji" />
						</div>

						<div className={styles.box}>
							<div>
								<h2>Pasja tworzenia</h2>
								<p>Wkładamy wiele energii, aby dzielić się z Wami tym co najlepsze w polskiej tradycji.</p>
							</div>
							<img src="/img/icons/dumplings.svg" alt="Pasja tworzenia" />
						</div>
					</div>
				</div>
			</section>

			<section id="products" className={styles.sectionProducts}>
				<div className="container">
					<h2>Nasze produkty</h2>

					<div className={styles.wrapperProducts}>
						{
							products.map( ( { title, image, details } ) => (
								<div key={title} className={styles.product} tabIndex={0}>
									<div className={styles.image}>
										<img src={image} alt={title} loading="lazy" />
									</div>

									<div className={styles.title}>
										<h3>{title}</h3>
									</div>

									{details.length ? (
										<div className={styles.details}>
											<ul>
												{details.map( d => (
													<li key={d}>{d}</li>
												) )}
											</ul>
										</div>
									) : null}
								</div>
							) )
						}
					</div>
				</div>
			</section>

			<section id="contact" className={styles.sectionContact}>
				<div className="container">
					<h2>Kontakt</h2>

					<div className={styles.contactHeading}>
						<p>Zapraszamy do naszych sklepów firmowych gdzie można składać zamówienia na imprezy okolicznościowe oraz zakupić gotowy produkt na zimno lub ciepło.</p>

						<div className={styles.contactImages}>
							<a href="http://www.chrzanolandia.com.pl/" target="_blank" rel="noreferrer">
								<img src="/img/chrzanolandia.svg" width={195} alt="Chrzanolandia" loading="lazy" />
							</a>
							<a href="http://www.partnerstwonajurze.pl/" target="_blank" rel="noreferrer">
								<img src="/img/jura.svg" width={90} alt="Partnerstwo na jurze" loading="lazy" />
							</a>
						</div>
					</div>

					<div className={styles.shops}>
						<div className={styles.shop}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.2605468882716!2d19.583985315939163!3d50.04395032445201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716f37d4ddb95c7%3A0xda49d2870693963e!2sPlac%20S%C5%82oneczny%202%2C%2032-566%20Brod%C5%82a!5e0!3m2!1spl!2spl!4v1654835972640!5m2!1spl!2spl"
								width="267"
								height="170"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							/>
							<h3>"Top market"</h3>
							<p>
								<img src="/img/icons/place.svg" alt="Top market Brodła miejsce" loading="lazy" />
								<span>Plac Słoneczny 2, Brodła</span>
							</p>
							<p>
								<img src="/img/icons/time.svg" alt="Top market Brodła otwarcie" loading="lazy" />
								<span>6-20 od poniedziałku do soboty</span>
							</p>
							<p>
								<img src="/img/icons/phone.svg" alt="Top market Brodła telefon" loading="lazy" />
								<span>Tel. 12/28-32-860</span>
							</p>
						</div>

						<div className={styles.shop}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.777917043635!2d19.58295471593847!3d50.015517626488425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716f4b23ea61df5%3A0xd10d62c16a2ac565!2sRubinowa%2C%2032-071%20Kamie%C5%84!5e0!3m2!1spl!2spl!4v1654836255057!5m2!1spl!2spl"
								width="267"
								height="170"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							/>
							<h3>"Top market"</h3>
							<p>
								<img src="/img/icons/place.svg" alt="Top market Brodła miejsce" loading="lazy" />
								<span>ul. Rubinowa, Kamień</span>
							</p>
							<p>
								<img src="/img/icons/time.svg" alt="Top market Brodła otwarcie" loading="lazy" />
								<span>5.30-21 od poniedziałku do soboty</span>
							</p>
							<p>
								<img src="/img/icons/phone.svg" alt="Top market Brodła telefon" loading="lazy" />
								<span>Tel. 12/256-00-64</span>
							</p>
						</div>

						<div className={styles.shop}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d640.5663981070303!2d19.5855492!3d50.0438558!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716f37d4f29ed95%3A0xe7323e2657ef4e39!2zUm96ZHJvxbxlIDEsIDMyLTU2NiBCcm9kxYJh!5e0!3m2!1spl!2spl!4v1654836327200!5m2!1spl!2spl"
								width="267"
								height="170"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							/>
							<h3>Zakład Garmażeryjny Brodway - Brodła</h3>
							<p>
								<img src="/img/icons/place.svg" alt="Top market Brodła miejsce" loading="lazy" />
								<span>ul.Rozdroże 1, Brodła</span>
							</p>
							<p>
								<img src="/img/icons/time.svg" alt="Top market Brodła otwarcie" loading="lazy" />
								<span>6-18 od poniedziałku do soboty</span>
							</p>
							<p>
								<img src="/img/icons/phone.svg" alt="Top market Brodła telefon" loading="lazy" />
								<span>Tel. 12/28-32-860</span>
							</p>
							<p>
								<img src="/img/icons/email.svg" alt="Top market Brodła email" loading="lazy" />
								<span>z.g.b@op.pl</span>
							</p>
						</div>
					</div>
				</div>

				<img className={styles.shopsWood} src="/img/footer-wood.png" alt="Drewno" loading="lazy" />
			</section>
		</>
	);
};

export default IndexPage;
