// extracted by mini-css-extract-plugin
export var box = "index-module--box--mWwp1";
export var contactHeading = "index-module--contactHeading--ZKvy9";
export var contactImages = "index-module--contactImages--H3PV3";
export var copy = "index-module--copy--NdDey";
export var details = "index-module--details--pCBKW";
export var image = "index-module--image--a5mc7";
export var mainSlider = "index-module--mainSlider--EleO7";
export var mainWrapper = "index-module--mainWrapper--7vbOo";
export var product = "index-module--product--8k0x+";
export var sectionContact = "index-module--sectionContact--BeOgx";
export var sectionMain = "index-module--sectionMain--iTiDS";
export var sectionProducts = "index-module--sectionProducts--vELpS";
export var sectionValues = "index-module--sectionValues--WJEM6";
export var shop = "index-module--shop--b9L+m";
export var shops = "index-module--shops--aMPL+";
export var shopsWood = "index-module--shopsWood--fWbLR";
export var title = "index-module--title--wpBVh";
export var valuesWrapper = "index-module--valuesWrapper--tB76G";
export var wrapperProducts = "index-module--wrapperProducts--dLZZB";